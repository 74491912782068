import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { appNames } from 'utils/constants'
import { IWorkbook } from 'utils/constants/interfaces'

const selectmain = (state: RootState) => state.main
const state = (state: RootState) => state

const selectWorkbookListItems = createSelector(state, (root:any) => {
  return root.auth.user?.subscriptions[root.main.selectedSuscriptionIndex].workbooks
})

const selectWorkbookTier = createSelector(state, (root: any) => {
  const workbookGuid = root.main.selectedWorkbookGuid
  return root.auth.user.subscriptions[root.main.selectedSuscriptionIndex].workbooks.find((wb: IWorkbook)=>wb.workbookGuid === workbookGuid)?.groupTier
})

const selectHeaderTabs = createSelector(state, (root: any) => {
  const compareFn = (a: any, b: any) => a.groupOrder - b.groupOrder;

  const subscription = root.auth.user?.subscriptions[root.main.selectedSuscriptionIndex];
  const isActiveVMIQ = subscription?.vmiqAllowed;
  const userWorkBooks = Array.isArray(subscription?.workbooks) ? [...subscription.workbooks] : [];

  const workbooks = userWorkBooks
    .filter((wb: any) => !wb.isVMiQ)
    .sort(compareFn);

  const result = workbooks.reduce((acc: any, curr: any) => {
    const groupString = curr.groupString.charAt(0) + curr.groupString.slice(1).toLowerCase();
    if (
      groupString === 'Undefined' ||
      acc.find((g: any) => g.groupString === groupString)
    ) {
      return acc;
    }
    return [...acc, { ...curr, groupString }];
  }, []);

  if (isActiveVMIQ) {
    const vmiqWorkbook = userWorkBooks.find((wb: any) => wb.isVMiQ);
    if (vmiqWorkbook) {
      result.push({ ...vmiqWorkbook, groupString: 'VMiQ', groupOrder: 999 });
    }
  }

  return result;
});



const selectWorkbookTabs = createSelector(state, (root: any) => {
  const compareFn = (a: any, b: any) => a.tabGroupOrder - b.tabGroupOrder;
  const selectedWorkbookGroup = root.main.selectedWorkbookGroup;
  const subscription = root.auth.user?.subscriptions[root.main.selectedSuscriptionIndex];
  const allWorkbooks = Array.isArray(subscription?.workbooks) ? subscription.workbooks : [];

  let filteredWorkbooks = allWorkbooks;

  if (selectedWorkbookGroup === 'VMiQ') {
    filteredWorkbooks = allWorkbooks.filter((wb: any) => wb.isVMiQ);
  } else {
    filteredWorkbooks = allWorkbooks.filter(
      (wb: any) => wb.groupString === selectedWorkbookGroup?.toUpperCase()
    );
  }

  return filteredWorkbooks
    .sort(compareFn)
    .map((wb: any) => ({
      workbookGuid: wb.workbookGuid,
      tabLabel: wb.workbookDisplayName,
    }));
});



const mainSelectors = {
  selectmain,
  selectHeaderTabs,
  selectWorkbookTabs,
  selectWorkbookListItems,
  selectWorkbookTier
}

export default mainSelectors
