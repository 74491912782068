import { getColor } from "utils"
import styled from 'styled-components'
import { devices } from 'utils/constants/media-queries'


const StyledHeader = styled.div`
    position: fixed;
    top:0;
    justify-content: center;
    align-items: center;
    display: flex;
    * {
        user-select: none;
    }
    justify-content: space-between;
    background: ${getColor('brandDark')};
    width: 100%;
    height: 72px;
    padding: 0 24px;
    white-space: nowrap;
    gap: 24px;
    &.focusHeader {
        z-index: 201;
    }
    .header-left-container {
        height: 100%;
        gap: 40px;
        .header-logo-container {
            max-width: 135px;
            height: 80px;
        }
        svg {
            color: ${getColor('white')};
            object-fit: contain;
            width: 22px;
            max-height: 20px;
        }
        .links-container {
            gap: 16px;
            height: 100%;
            display: none;
            .header-link-container {
                .trigger-container {
                    gap: 8px;
                    height: 100%;
                    flex: 1;
                    .arrow {
                        border-color: ${getColor('white')};
                    }
                    p {
                        color: ${getColor('white')};
                    }
                }
                height: 100%;
                cursor: pointer;
                &.active {
                    background: ${getColor('clearOverlay')};
                }
                :not(:hover){
                    background: transparent;
                }
                &.selected {
                    background: ${getColor('clearOverlay')};
                }
            }
            .premium-download-container{
                width: 120px;
                height: 40px;
                border-radius: 40px;
                padding: 0 10px;
                &:hover{
                        background: #D1F9F7;
                        p{
                            color: ${getColor('brandDark')};
                        }
                        svg{
                            path{
                                    fill: ${getColor('brandDark')};
                            }
                        }
                }
                &.switch{
                    position: absolute;
                    right: 150px;
                    &:hover{
                        background: #e8f9f8;
                        p{
                            color: ${getColor('brandDark')};
                        }
                        svg{
                            path{
                                    stroke: ${getColor('brandDark')};
                            }
                        }
                    }
                }
                button{
                    width: 100%;
                    background: none;
                    border: none;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    &:hover{
                        cursor: pointer;
                    }
                }
                p{
                    display: inline-block;
                    margin-right: 5px;
                    color: white;
                    font-size: 16px;
                    font-weight: 600;
                }
                svg{
                    path{
                        opacity: 1;
                        fill: white;
                    }
                }
            }
            .vmiq-link {
                font-size: 18px;
                font-weight: 700;
                margin-left: 8%;
                color: ${getColor('white')};
                transition: all 0.2s ease-in-out;
                &:hover {
                    background: ${getColor('clearOverlay')};
                }
                .trigger-container {
                    p {
                    color: ${getColor('white')};
                    font-size: 20px;
                    font-weight: 700;
                    }
                }
            }
        }
    }
    .header-right-container {
        height: 100%;
        gap: 16px;
        button {
            height: 32px;
            width: 32px;
            align-items: center;
            display: flex;
            background: ${getColor('white')};
            cursor: pointer;
            padding: 0;
            gap: 4px;
            svg {
                color: ${getColor('brandDark')};
                font-size: 22px;
            }
        }
        p{
            display: none;
        }
    }
    .side-menu-container {
        display: none;
        border: 1px solid ${getColor('border')};
        border-radius: 16px;
        position: absolute;
        top: 72px;
        right: 0;
        min-width: 250px;
        background: rgb(251, 250, 255);
        flex-direction: column;
        align-items: stretch;
        transition: ease-in-out .3s all;
        transform: translateY(-16px);
        opacity: 0.0;
        visibility: hidden;
        &.visible {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
        }
        
        .side-menu-content-container {
            flex: 1;
            max-height: 100%;
            flex-direction: column;
            overflow: auto;
            padding: 0 30px;
            align-items: center;
            justify-content: flex-start;
                button {
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 0;
                    background: rgb(251, 250, 255);
                    border-bottom: 1px solid ${getColor('border')};
                    a {
                        text-decoration: none;
                        color: ${getColor('text')};
                    }
                    &:hover {
                        p, svg {

                            color: ${getColor('brandDark')};
                        }
                    }
                    &:nth-last-of-type(1) {
                        border-bottom: 0px;
                        &:hover {
                            p, svg {

                                color: ${getColor('error')};
                            }
                        }
                    }
                }
        }
    }
    .outer-area-container {
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        right: 0;
        visibility: hidden;
        &.visible {
            visibility: visible;
        }
    }

@media only screen and ${devices.tablet}{
    position: relative;
    padding: 0 34px;
    .header-left-container{
        .links-container{
            display: flex;
        }
    }
    .header-right-container {
        p{
            display: none;
        }
    }
    .side-menu-container {
        display: flex;
    }
}
@media only screen and ${devices.laptop}{
    .header-left-container{
        .links-container{
            display: flex;
        }
    }
    .header-right-container {
        p{
            display: block;
        }
    }
}
`

export default StyledHeader